import interact from 'interactjs';

export const setDraggable = (element: HTMLElement) => {
  interact(element).draggable({
    listeners: {
      move(event) {
        const target = event.target;
        // keep the dragged position in the data-x/data-y attributes
        const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
        const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
        const angle = parseFloat(target.getAttribute('data-angle') || '0');

        // translate the element
        target.style.transform = `translate(${x}px, ${y}px) rotate(${angle}rad)`;

        // update the posiion attributes
        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
      },
    },
    inertia: true,
    // keep the element within the area of it's parent
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: '.app-preview-container',
        endOnly: true,
      }),
    ],
  });
};

export const setResizable = (element: HTMLElement) => {
  interact(element).resizable({
    edges: { top: true, left: true, bottom: true, right: true },
    listeners: {
      move: function (event) {
        const target = event.target;
        let x = parseFloat(target.getAttribute('data-x')) || 0;
        let y = parseFloat(target.getAttribute('data-y')) || 0;

        // update the element's style
        target.style.width = event.rect.width + 'px';
        target.style.height = event.rect.height + 'px';

        // translate when resizing from top or left edges
        x += event.deltaRect.left;
        y += event.deltaRect.top;

        const angle = parseFloat(target.getAttribute('data-angle') || '0');

        target.style.transform = `translate(${x}px, ${y}px) rotate(${angle}rad)`;

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
      },
    },
    modifiers: [
      interact.modifiers.aspectRatio({
        ratio: 'preserve',
      }),
      interact.modifiers.restrictSize({
        min: { width: 20, height: 20 },
      }),
    ],
  });
};

export const setRotatable = (element: HTMLElement) => {
  const handle = <HTMLElement>element.querySelector('.app-sticker-rotate-handle');
  const close = <HTMLElement>element.querySelector('.app-close-button');
  const image = <HTMLElement>element.querySelector('img');

  if (!handle || !close || !image) {
    return;
  }

  const getDragAngle = (event: any) => {
    const startAngle = parseFloat(element.getAttribute('data-angle') || '0');
    const center = {
      x: parseFloat(element.getAttribute('data-center-x') || '0'),
      y: parseFloat(element.getAttribute('data-center-y') || '0'),
    };
    const angle = Math.atan2(center.y - event.clientY, center.x - event.clientX);

    return angle - startAngle;
  };

  interact(handle).draggable({
    onstart: function (event) {
      const rect = image.getBoundingClientRect();

      // store the center as the element has css `transform-origin: center center`
      element.setAttribute('data-center-x', String(rect.left + rect.width / 2));
      element.setAttribute('data-center-y', String(rect.top + rect.height / 2));
      // get the angle of the element when the drag starts
      element.setAttribute('data-angle', String(getDragAngle(event)));
    },
    onmove: function (event) {
      const x = parseFloat(element.getAttribute('data-x') || '0');
      const y = parseFloat(element.getAttribute('data-y') || '0');
      const angle = getDragAngle(event);

      const center = {
        x: parseFloat(element.getAttribute('data-center-x') || '0'),
        y: parseFloat(element.getAttribute('data-center-y') || '0'),
      };

      // update transform style on dragmove
      element.style.transform = `translate(${x}px, ${y}px) rotate(${angle}rad)`;
    },
    onend: function (event) {
      // save the angle on dragend
      element.setAttribute('data-angle', String(getDragAngle(event)));
    },
  });
};

export const createCloseButton = (node: HTMLElement) => {
  const closeButton = document.querySelector('body > div > .app-close-button');

  if (!closeButton) {
    return null;
  }

  const clonedButton = closeButton.cloneNode(true);
  clonedButton.addEventListener('click', () => node.parentNode?.removeChild(node));

  return clonedButton;
};

export const createScaleBorder = () => {
  const scaleBorder = document.querySelector('body > div > .app-sticker-border');

  if (!scaleBorder) {
    return null;
  }

  const clonedBorder = scaleBorder.cloneNode(true);

  return clonedBorder;
};

export const createActionableImage = (src: string, attributes: object = {}) => {
  const div = document.createElement('div');
  Object.entries(attributes).forEach(([key, value]) => div.setAttribute(key, value));
  div.setAttribute('tabindex', '0');
  div.classList.add('image-container');

  const image = new Image();
  image.src = src;
  div.append(image);

  const closeButton = createCloseButton(div);

  if (closeButton) {
    div.append(closeButton);
  }

  const scaleBorder = createScaleBorder();

  if (scaleBorder) {
    div.append(scaleBorder);
  }

  setDraggable(div);
  setResizable(div);
  setRotatable(div);

  return div;
};

export const uploadFile = (file: File) => {
  try {
    const reader = new FileReader();
    reader.onload = (f => e => {
      if (!e.target) {
        return;
      }

      const dataURL = e.target.result;
      if (!dataURL) {
        return;
      }

      if (!dataURL.toString().startsWith('data:image')) {
        setUploadError(f.name, 'Filetype not supported (not an image)');
        return;
      }

      setUploadedImage(f.name, dataURL.toString());
    })(file);
    reader.readAsDataURL(file);
  } catch (e) {
    console.error(e);
  }
};

export const setUploadedImage = (filename: string, dataURL: string) => {
  removeUploadedImage();

  const container = document.getElementById('avatar-sticker-container');
  const image = createActionableImage(dataURL.toString(), { 'data-type': 'upload', id: 'avatar-uploaded-img' });

  if (container) {
    container.append(image);
  }

  const uploadContainer = document.getElementById('upload-container');
  const uploadedContainer = document.getElementById('uploaded-img');
  const uploadErrorContainer = document.getElementById('upload-error');

  if (uploadContainer && uploadedContainer && uploadErrorContainer) {
    hide(uploadContainer);
    show(uploadedContainer);
    hide(uploadErrorContainer);

    const uploadedImg = uploadedContainer.querySelector('img');
    const uploadedFilename = uploadedContainer.querySelector('.app-inputs-upload-filename');

    if (uploadedImg && uploadedFilename) {
      uploadedImg.src = dataURL;
      uploadedFilename.innerHTML = '';
      uploadedFilename.append(document.createTextNode(filename));
    }
  }
};

export const setUploadError = (filename: string, error: string) => {
  const uploadContainer = document.getElementById('upload-container');
  const uploadedContainer = document.getElementById('uploaded-img');
  const uploadErrorContainer = document.getElementById('upload-error');

  if (uploadContainer && uploadedContainer && uploadErrorContainer) {
    hide(uploadContainer);
    hide(uploadedContainer);
    show(uploadErrorContainer);

    const uploadError = uploadErrorContainer.querySelector('.app-inputs-upload-error');
    const uploadedFilename = uploadErrorContainer.querySelector('.app-inputs-upload-filename');

    if (uploadError && uploadedFilename) {
      uploadError.innerHTML = '';
      uploadError.append(document.createTextNode(error));
      uploadedFilename.innerHTML = '';
      uploadedFilename.append(document.createTextNode(filename));
    }
  }
};

export const removeUploadedImage = () => {
  const container = document.getElementById('avatar-sticker-container');
  const existing = document.getElementById('avatar-uploaded-img');

  if (container && existing) {
    container.removeChild(existing);
  }

  const uploadContainer = document.getElementById('upload-container');
  const uploadedContainer = document.getElementById('uploaded-img');
  const uploadErrorContainer = document.getElementById('upload-error');

  if (uploadContainer && uploadedContainer && uploadErrorContainer) {
    show(uploadContainer);
    hide(uploadedContainer);
    hide(uploadErrorContainer);

    const uploadedImg = uploadedContainer.querySelector('img');

    if (uploadedImg) {
      uploadedImg.src = '';
    }
  }
};

export const show = (node: HTMLElement) => {
  node.style.display = 'flex';
};

export const hide = (node: HTMLElement) => {
  node.style.display = 'none';
};
