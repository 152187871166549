import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import { createActionableImage, removeUploadedImage, uploadFile } from './utils';

const handleUpload = () => {
  const uploadButton = document.getElementById('upload-button');

  uploadButton?.addEventListener('change', ev => {
    const inputElement = <HTMLInputElement>ev.target;

    if (!inputElement || !inputElement.files) {
      return;
    }

    uploadFile(inputElement.files[0]);
  });

  /*
  const uploadContainer = document.getElementById('upload-container');

  uploadContainer?.addEventListener('drop', ev => {
    ev.preventDefault();
    ev.stopPropagation();

    const files = ev.dataTransfer?.files;

    if (files) {
      uploadFile(files[0]);
      return;
    }

    const items = ev.dataTransfer?.items;

    if (items) {
      const item = items[0];

      if (item.kind === 'file') {
        const file = item.getAsFile();

        if (file) {
          uploadFile(file);
        }
      }
    }
  });

  uploadContainer?.addEventListener('dragover', ev => {
    ev.preventDefault();
    ev.stopPropagation();
  });
  */
};

const handleDelete = () => {
  const deleteButtons = document.querySelectorAll('.app-inputs-delete-button');

  deleteButtons.forEach(button => button.addEventListener('click', () => removeUploadedImage()));
};

const handleDownload = () => {
  const downloadButton = document.getElementById('download-button');

  downloadButton?.addEventListener('click', () => {
    const avatar = document.getElementById('avatar');

    if (!avatar) {
      return;
    }

    avatar.style.overflow = 'hidden';

    html2canvas(avatar, { allowTaint: true, backgroundColor: null }).then(canvas => {
      canvas.toBlob(blob => {
        if (blob) {
          FileSaver.saveAs(blob, 'MyNostraPFP.png');
        }

        avatar.style.overflow = 'initial';
      });
    });
  });
};

const handleChangeBackground = () => {
  const backgrounds = document.querySelectorAll('.app-inputs-backgrounds-input li');

  backgrounds.forEach((background, i) => {
    const container = document.getElementById('avatar-background-container');
    const backgroundImage = background.querySelector('img');

    if (!container || !backgroundImage) {
      return;
    }

    background.addEventListener('click', () => {
      container.innerHTML = '';

      if (i > 0) {
        const image = new Image();
        image.src = backgroundImage.src;
        container.append(image);
      }
    });
  });
};

const handleAddSticker = () => {
  const stickers = document.querySelectorAll('.app-inputs-stickers-input li');

  stickers.forEach(sticker => {
    const container = document.getElementById('avatar-sticker-container');
    const stickerImage = sticker.querySelector('img');

    if (!container || !stickerImage) {
      return;
    }

    sticker.addEventListener('click', () => {
      const image = createActionableImage(stickerImage.src, { 'data-type': 'sticker' });
      container.append(image);
    });
  });
};

const handleChangeFrame = () => {
  const frames = document.querySelectorAll('.app-inputs-frames-input li');

  frames.forEach((frame, i) => {
    const container = document.getElementById('avatar-frame-container');
    const frameImage = frame.querySelector('img');

    if (!container || !frameImage) {
      return;
    }

    frame.addEventListener('click', () => {
      container.innerHTML = '';

      if (i > 0) {
        const image = new Image();
        image.src = frameImage.src;
        container.append(image);
      }
    });
  });
};

handleUpload();
handleDelete();
handleDownload();
handleChangeBackground();
handleChangeFrame();
handleAddSticker();
